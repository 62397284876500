import { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import Select from 'react-select';
import { useGetMasterDataQuery } from '../../../Redux/services/masterData';

const customSelectStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '500',
    opacity: '.7',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '1.15rem',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    borderRadius: '1.15rem',
    backgroundColor: 'var(--bs-dropdown-bg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--bs-gray-300)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
    fontSize: '1.1rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    fontSize: '1.1rem',
    color: 'var(--bs-gray-700)',
  }),
  cursor: 'default',
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: 'var(--bs-dropdown-bg)',
    color: isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':hover': {
      backgroundColor: 'var(--bs-component-hover-bg)',
    },
  }),
};

function getCustomLabel(option) {
  const { value, label } = option;
  const theme = document.querySelector('[data-bs-theme]')?.getAttribute('data-bs-theme');
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          display: 'inline-block',
          width: 12,
          height: 12,
          marginRight: 10,
          borderRadius: '50%',
          backgroundColor: theme === 'light' ? value.textHexCode : value.darkTextHexCode,
        }}
      />
      {label}
    </div>
  );
}

function MilestoneDetailsForm({
  milestoneList, setMilestoneList, modalState, show, onHide,
}) {
  const {
    register, handleSubmit, control, formState: { errors }, reset, setValue,
  } = useForm({
    defaultValues: { name: '', color: null },
  });

  const [colorList, setColorList] = useState([]);

  const { data: masterData, isError, error } = useGetMasterDataQuery();
  if (isError) {
    window.toastr.error(error.message ?? 'Something went wrong!');
  }

  useEffect(() => {
    if (modalState.modalValues) {
      reset({
        name: modalState.modalValues.name ?? '',
        color:
          colorList?.find((color) => color.value.id === modalState.modalValues.colorId) ?? null,
      });
    }
  }, [modalState.modalValues, reset, colorList]);

  useEffect(() => {
    if (masterData) {
      const colorOptions = masterData.data.milestoneColors?.map((color) => ({
        value: {
          id: color.id,
          backgroundHexCode: color.backgroundHexCode,
          darkBackgroundHexCode: color.darkBackgroundHexCode,
          textHexCode: color.textHexCode,
          darkTextHexCode: color.darkTextHexCode,
        },
        label: color.name,
      }));
      setColorList(colorOptions);
    }
  }, [masterData]);

  const onSubmit = (formData) => {
    const newMilestoneData = {
      name: formData.name,
      colorId: formData.color.value.id,
      colorName: formData.color.label,
      textHexCode: formData.color.value.textHexCode,
      darkTextHexCode: formData.color.value.darkTextHexCode,
      backgroundHexCode: formData.color.value.backgroundHexCode,
      darkBackgroundHexCode: formData.color.value.darkBackgroundHexCode,
    };

    const updatedMilestoneList = { ...milestoneList };

    if (modalState.modalValues.modalMode === 'add') {
      const newMilestone = { ...newMilestoneData, id: uuid(), isNew: true };
      updatedMilestoneList.inprogressMilestone = [
        ...milestoneList.inprogressMilestone, newMilestone,
      ];
    }
    if (modalState.modalValues.modalMode === 'edit') {
      if (modalState.modalValues.id === milestoneList?.todoMilestone.id) {
        updatedMilestoneList.todoMilestone = {
          ...milestoneList.todoMilestone, ...newMilestoneData,
        };
      } else if (modalState.modalValues.id === milestoneList?.completeMilestone.id) {
        updatedMilestoneList.completeMilestone = {
          ...milestoneList.completeMilestone, ...newMilestoneData,
        };
      } else {
        updatedMilestoneList.inprogressMilestone = milestoneList
          ?.inprogressMilestone?.map((item) => (
            item.id === modalState.modalValues.id ? { ...item, ...newMilestoneData } : item
          ));
      }
    }

    setMilestoneList(updatedMilestoneList);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered size="sm">
      <Modal.Header closeButton>
        <Modal.Title>{modalState.modalValues?.modalMode === 'edit' ? 'Edit Milestone' : 'Add Milestone'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3 pb-2">
            <Form.Label>Name<span className="text-danger">*</span></Form.Label>
            <Form.Control
              style={{ fontWeight: '500', color: 'var(--bs-gray-700)' }}
              {...register('name', {
                required: { value: true, message: 'Required' },
                minLength: { value: 1, message: 'Minimum 1 character required' },
                maxLength: { value: 50, message: 'Maximum 50 characters allowed' },
              })}
              type="text"
              placeholder="Milestone Name"
              autoFocus
              onBlur={(e) => {
                const trimmedValue = e.target.value.trim();
                if (trimmedValue !== '') {
                  setValue('name', trimmedValue, { shouldValidate: true });
                }
              }}
            />
            {errors?.name
                  && <div className="text-danger small"> {errors.name?.message} </div>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Color<span className="text-danger">*</span></Form.Label>
            <Controller
              name="color"
              rules={{
                required: { value: true, message: 'Required' },
              }}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={colorList}
                  placeholder="Select Color"
                  styles={customSelectStyles}
                  defaultValue={
                    colorList?.find((option) => (
                      option.value === modalState.modalValues?.color
                    ))
                  }
                  formatOptionLabel={getCustomLabel}
                  isLoading={!masterData}
                />
              )}
            />
            {errors?.color
                  && <div className="text-danger small"> {errors.color?.message} </div>}
          </Form.Group>
          <div className="justify-content-start text-left pt-5 mt-5">
            <button type="submit" className="btn btn-sm btn-light save-btn">Save</button>
            <button type="button" className="btn btn-sm btn-light ms-3 save-btn" onClick={onHide}>Close</button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default MilestoneDetailsForm;
