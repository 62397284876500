import React from 'react';

function DonutChartLabels({ milestone }) {
  const theme = document.querySelector('[data-bs-theme]')?.getAttribute('data-bs-theme');
  return (
    <div className="d-flex fs-6 fw-semibold align-items-center my-1 me-4">
      <div
        className="bullet w-8px h-8px rounded-2"
        style={{ backgroundColor: theme === 'dark' ? milestone['MilestoneColor.darktextHexCode'] : milestone['MilestoneColor.textHexCode'], marginRight: '10px' }}
      />
      {/* <div className="bullet w-8px h-8px rounded-2"
      style={{ backgroundColor: milestone['MilestoneColor.badgeHexCode'],
      marginRight: '10px' }} /> */}
      <div className="text-gray-500 flex-grow-1 me-4">
        {milestone.name}
      </div>
      <div className="fw-bolder text-gray-700 text-xxl-end">
        {milestone?.taskCount}
      </div>
    </div>
  );
}

export default DonutChartLabels;
