import { Modal } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useSelector } from 'react-redux';
import MilestoneCard from './MilestoneCard';
import MilestoneDetailsForm from './MilestoneDetailsForm';
import { useEditProjectMilestoneMutation, useCreateProjectMutation, useAddProjectMilestoneMutation } from '../../../Redux/services/project';
import { userRoleConstants } from '../../../utils/constant';

function MilestoneList({
  isLoading, modalState, openMilestoneList, openMilestoneDetailsForm,
  show, onHide, milestoneList, setMilestoneList, projectId, mode, milestoneWiseTaskCount,
  projectDetails, resetProjectDeatils,
}) {
  const [createProject] = useCreateProjectMutation();
  const [addProjectMilestone, { isLoading: isAdding }] = useAddProjectMilestoneMutation();
  const [editProjectMilestone, { isLoading: isEditing }] = useEditProjectMilestoneMutation();
  const { id: roleId } = useSelector((state) => state.user.role);

  const handleDelete = (milestoneId) => {
    let deleteWarningText = 'Are you sure you want to delete this milestone?';
    const doesMilestoneHasTask = milestoneWiseTaskCount
      ?.find((milestone) => milestone.id === milestoneId)?.taskCount > 0;
    if (mode === 'editMilestoneList' && doesMilestoneHasTask) {
      deleteWarningText = 'This milestone has tasks, move the tasks to another milestone before deleting.';
    }
    Swal.fire({
      text: deleteWarningText,
      icon: 'warning',
      iconColor: 'var(--bs-primary)',
      showConfirmButton: !(mode === 'editMilestoneList' && doesMilestoneHasTask),
      showCancelButton: true,
      heightAuto: false,
      confirmButtonText: 'Yes',
      cancelButtonColor: '#161221',
      cancelButtonText: (mode === 'editMilestoneList' && doesMilestoneHasTask) ? 'Close' : 'Cancel',
      customClass: {
        confirmButton: 'btn btn-sm btn-secondary bg-dark',
        cancelButton: 'btn btn-sm btn-outline-secondary ms-3',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const milestoneToBeDeleted = milestoneList?.inprogressMilestone?.find((item) => (
          item.id === milestoneId
        ));
        let updatedItems = [];
        if (milestoneToBeDeleted?.isNew) {
          updatedItems = milestoneList?.inprogressMilestone?.filter((item) => (
            item.id !== milestoneId
          ));
        } else {
          updatedItems = milestoneList?.inprogressMilestone?.map((item) => {
            if (item.id === milestoneId) {
              return { ...item, delete: true };
            }
            return item;
          });
        }
        setMilestoneList({ ...milestoneList, inprogressMilestone: updatedItems });
      }
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (roleId !== userRoleConstants.SUPER_ADMIN) {
      return;
    }

    if (result.destination && result.source.index !== result.destination.index) {
      const reorderedItems = reorder(
        milestoneList?.inprogressMilestone,
        result.source.index,
        result.destination.index,
      );

      setMilestoneList({ ...milestoneList, inprogressMilestone: reorderedItems });
    }
  };

  const getListStyle = () => ({
    display: 'flex',
    overflow: 'auto',
  });

  const submitMilestoneList = async () => {
    const sanitizedMilestoneList = {};
    sanitizedMilestoneList.todoMilestone = {
      id: milestoneList.todoMilestone.id,
      name: milestoneList.todoMilestone.name,
      colorId: milestoneList.todoMilestone.colorId,
    };
    sanitizedMilestoneList.completeMilestone = {
      id: milestoneList.completeMilestone.id,
      name: milestoneList.completeMilestone.name,
      colorId: milestoneList.completeMilestone.colorId,
    };
    sanitizedMilestoneList.inprogressMilestones = milestoneList
      ?.inprogressMilestone?.map((item) => {
        const requiredItem = {
          id: item.id,
          name: item.name,
          colorId: item.colorId,
        };
        if (item.delete) {
          requiredItem.delete = true;
        }
        if (item.isNew) {
          delete requiredItem.id;
        }
        return requiredItem;
      });

    let projectData = {};
    if (mode === 'addMilestoneList') {
      delete sanitizedMilestoneList.todoMilestone.id;
      delete sanitizedMilestoneList.completeMilestone.id;

      const {
        name, startDate, endDate, status, owner, users, projectAlert, taskAlert,
      } = projectDetails.projectForm;
      projectData = {
        name,
        startDate: moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        endDate: moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        status: status.value,
        owner: owner.value,
        users: users.map((user) => user.value),
        projectAlert: projectAlert ?? false,
        taskAlert: taskAlert ?? false,
      };
    }

    try {
      if (mode === 'editMilestoneList') {
        await editProjectMilestone({ projectId, milestoneList: sanitizedMilestoneList });
        window.toastr.success('Milestones updated successfully!');
        onHide();
      }
      if (mode === 'addMilestoneList') {
        const project = await createProject(projectData).unwrap();
        window.toastr.success('Project Added Successfully');
        await addProjectMilestone({
          projectId: project?.data?.id, milestoneList: sanitizedMilestoneList,
        });
        window.toastr.success('Milestones added successfully!');
        resetProjectDeatils();
      }
    } catch (error) {
      window.toastr.error(error.message ?? 'Something went wrong!');
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" centered scrollable>
        <Modal.Header className="d-flex flex-column align-items-start">
          <Modal.Title>Task Milestones</Modal.Title>
          <small className="text-muted">
            {roleId === userRoleConstants.SUPER_ADMIN
              ? 'Add, Edit or Re-arrange milestones'
              : 'View milestones'}
          </small>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <div>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center min-vh-50 w-100" style={{ height: '71px' }}>
                <span className="spinner-border spinner-border-lg align-middle ms-2" />
              </div>
            ) : (
              <div className="d-flex justify-content-between">
                <div className="bg-light-dark rounded p-2">
                  <MilestoneCard
                    milestoneData={milestoneList?.todoMilestone}
                    handleDelete={handleDelete}
                    modalState={modalState}
                    openMilestoneList={openMilestoneList}
                    openMilestoneDetailsForm={openMilestoneDetailsForm}
                    milestoneList={milestoneList}
                    setMilestoneList={setMilestoneList}
                    subtitle="All created tasks will be assigned this milestone"
                  />
                </div>
                {roleId !== userRoleConstants.SUPER_ADMIN
                && milestoneList?.inprogressMilestone?.length === 0 ? null : (
                  <div className="rounded p-2 mx-2 d-flex border-dashed border-gray-300">
                    <div>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="milestoneDroppable" direction="horizontal">
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                              {...provided.droppableProps}
                            >
                              {milestoneList?.inprogressMilestone?.map((item, index) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id.toString()}
                                  index={index}
                                  isDragDisabled={roleId !== userRoleConstants.SUPER_ADMIN}
                                >
                                  {(draggableProvided) => (
                                    <div
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      {...draggableProvided.dragHandleProps}
                                    >
                                      {item.delete ? null : (
                                        <MilestoneCard
                                          milestoneData={item}
                                          handleDelete={handleDelete}
                                          modalState={modalState}
                                          openMilestoneList={openMilestoneList}
                                          openMilestoneDetailsForm={openMilestoneDetailsForm}
                                          milestoneList={milestoneList}
                                          setMilestoneList={setMilestoneList}
                                          subtitle=""
                                        />
                                      )}

                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                    {milestoneList?.inprogressMilestone?.filter((item) => !item.delete)
                      .length < process.env.REACT_APP_MAX_IN_PROGRESS_MILESTONE_COUNT
                    && roleId === userRoleConstants.SUPER_ADMIN ? (
                      <div
                        className="border border-muted border-dashed border-gray-300 text-center rounded py-2 p-3 m-2 d-flex align-items-center justify-content-center"
                        style={{ width: '160px', height: '300px' }}
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          openMilestoneDetailsForm({
                            name: '', color: '', modalMode: 'add', milestoneId: '',
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            openMilestoneDetailsForm({ name: '', color: '' });
                          }
                        }}
                        aria-label="Add"
                      >
                        <div className="d-flex flex-column justify-content-center">
                          <span className="fs-4 fw-semibold text-warning d-block pb-2 text-truncate">
                            <i className="fa fa-plus me-1" />
                          </span>
                          <small>Add Milestone</small>
                        </div>
                      </div>
                      ) : null}
                  </div>
                  )}

                <div className="bg-light-dark rounded p-2">
                  <MilestoneCard
                    milestoneData={milestoneList?.completeMilestone}
                    handleDelete={handleDelete}
                    modalState={modalState}
                    openMilestoneList={openMilestoneList}
                    openMilestoneDetailsForm={openMilestoneDetailsForm}
                    milestoneList={milestoneList}
                    setMilestoneList={setMilestoneList}
                    subtitle="All completed tasks will be assigned this milestone"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="justify-content-start text-left pt-5 mt-5">
            {mode === 'editMilestoneList' && roleId === userRoleConstants.SUPER_ADMIN ? (
              <button type="submit" className="btn btn-sm btn-light save-btn" onClick={submitMilestoneList}>
                {isEditing ? (<><span className="spinner-border spinner-border-sm align-middle me-2" /><span>Saving</span></>) : 'Save'}
              </button>
            ) : null}
            {mode === 'addMilestoneList' ? (
              <button type="submit" className="btn btn-sm btn-light save-btn" onClick={submitMilestoneList}>
                {isAdding ? (<><span className="spinner-border spinner-border-sm align-middle me-2" /><span>Submiting</span></>) : 'Submit'}
              </button>
            ) : null}
            <button type="button" className="btn btn-sm btn-light ms-3 save-btn" onClick={onHide}>{mode === 'addMilestoneList' ? 'Back' : 'Close'}</button>
          </div>
        </Modal.Body>
      </Modal>
      {modalState.activeModal === 'milestoneDetailsForm' ? (
        <MilestoneDetailsForm
          milestoneList={milestoneList}
          setMilestoneList={setMilestoneList}
          modalState={modalState}
          show={modalState.activeModal === 'milestoneDetailsForm'}
          onHide={openMilestoneList}
        />
      ) : null}
    </>
  );
}

export default MilestoneList;
