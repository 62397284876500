/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Modal, Form, Row, Col,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import FlatPickr from 'react-flatpickr';
import { useGetMasterDataQuery } from '../../Redux/services/masterData';
import {
  projectStatusConstants, userRoleConstants, userStatusConstants, milestoneColorConstants,
  configurableModules,
} from '../../utils/constant';
import { decryptRole } from '../../utils/cryptoUtils';
import Milestones from './Milestones';

const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '500',
    opacity: '.7',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '1.15rem',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    borderRadius: '1.15rem',
    backgroundColor: 'var(--bs-dropdown-bg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--bs-gray-300)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
    fontSize: '1.1rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    fontSize: '1.1rem',
    color: 'var(--bs-gray-700)',
  }),
  cursor: 'default',
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: 'var(--bs-dropdown-bg)',
    color: isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':hover': {
      backgroundColor: 'var(--bs-component-hover-bg)',
    },
  }),
};

function AddProjectModal({
  show, onHide, handleShowModal,
}) {
  const { data: masterData } = useGetMasterDataQuery();
  const [decryptedRole, setDecryptedRole] = useState('');
  const {
    register, setError, clearErrors, reset, formState: { errors }, watch,
  } = useForm({ mode: 'onChange' });

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showMilestoneModal, setShowMilestoneModal] = useState(false);
  const [projectAlert, setProjectAlert] = useState(false);
  const [taskAlert, setTaskAlert] = useState(false);
  const [projectDetails, setProjectDetails] = useState({
    projectForm: {},
    milestoneList: {
      todoMilestone: {
        id: 1, name: 'To Do', colorId: milestoneColorConstants.TO_DO, editOnly: true, backgroundHexCode: '#ffd6d62a', darkBackgroundHexCode: '#ffd6d62a', textHexCode: '#E65F5F', darkTextHexCode: '#E65F5F',
      },
      completeMilestone: {
        id: 2, name: 'Completed', colorId: milestoneColorConstants.COMPLETED, editOnly: true, backgroundHexCode: '#9bf3c717', darkBackgroundHexCode: '#9bf3c717', textHexCode: '#4DC889', darkTextHexCode: '#4DC889',
      },
      inprogressMilestone: [
        {
          id: 3, name: 'In Progress', colorId: milestoneColorConstants.IN_PROGRESS, backgroundHexCode: '#ffe99926', darkBackgroundHexCode: '#ffe99926', textHexCode: '#DCAC02', darkTextHexCode: '#DCAC02', isNew: true,
        },
      ],
    },
    step: 'cancel',
  });
  useEffect(() => {
    if (projectDetails?.step === 'back') {
      reset({
        projectName: projectDetails?.projectForm?.name ?? '',
        startDate: projectDetails?.projectForm?.startDate ?? null,
        endDate: projectDetails?.projectForm?.endDate ?? null,
        status: projectDetails?.projectForm?.status ?? null,
        owner: projectDetails?.projectForm?.owner ?? null,
        users: projectDetails?.projectForm?.users ?? [],
      });
    }
  }, [projectDetails.step, projectDetails?.projectForm?.name, projectDetails?.projectForm?.startDate, projectDetails?.projectForm?.endDate, projectDetails?.projectForm?.status, projectDetails?.projectForm?.owner, projectDetails?.projectForm?.users, reset]);

  useEffect(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    setDecryptedRole(roleDecrypted);

    // eslint-disable-next-line
  }, []);

  // fetch logged in user from redux user slice
  const loggedInUser = useSelector((state) => state.user);

  useEffect(() => {
    if (show) {
      let loggedInOwner = null;
      const activeOwner = masterData?.data.owners.find(
        (owner) => owner.StatusId === userStatusConstants.ACTIVE && owner.id === loggedInUser.userId,
      );

      if (activeOwner) {
        loggedInOwner = decryptedRole?.id === userRoleConstants.ADMIN
          ? {
            value: activeOwner.id,
            label: `${activeOwner.firstName} ${activeOwner.lastName}`,
          }
          : null;
        setSelectedOwner(loggedInOwner);
      }

      reset();
    }
    // eslint-disable-next-line
  }, [show, masterData, loggedInUser.userId, reset]);

  const statusOptions = masterData?.data.projectStatuses?.filter((status) => (status.id !== projectStatusConstants.COMPLETED)).map((status) => ({ value: status.id, label: status.name })) || [];
  const ownerOptions = masterData?.data.owners?.filter((owner) => (owner.StatusId === userStatusConstants.ACTIVE)).map((owner) => ({
    value: owner.id,
    label: `${owner.firstName} ${owner.lastName}`,
  })) || [];
  const userOptions = masterData?.data.users
    ?.filter((user) => (user.StatusId === userStatusConstants.ACTIVE))
    .map((user) => ({ value: user.id, label: user.fullName })) || [];

  const validateFields = () => {
    let isValid = true;
    if (!selectedStartDate) {
      setError('startDate', { type: 'manual', message: 'Required.' });
      isValid = false;
    } else {
      clearErrors('startDate');
    }
    if (!selectedEndDate) {
      setError('endDate', { type: 'manual', message: 'Required.' });
      isValid = false;
    } else {
      clearErrors('endDate');
    }
    if (!selectedOwner) {
      setError('owner', { type: 'manual', message: 'Required.' });
      isValid = false;
    } else {
      clearErrors('owner');
    }

    if (selectedUsers.length === 0) {
      setError('users', { type: 'manual', message: 'At least one user is Required.' });
      isValid = false;
    } else {
      clearErrors('users');
    }

    return isValid;
  };

  const handleNext = () => {
    const validProjectName = watch().projectName.trim().length >= 3 && watch().projectName.trim().length <= 100;
    if (!validateFields() || !validProjectName) {
      return;
    }
    setShowMilestoneModal(!showMilestoneModal);
    setProjectDetails((prev) => ({
      ...prev,
      step: 'next',
      projectForm: {
        ...prev.projectForm,
        name: watch().projectName,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        status: selectedStatus,
        owner: selectedOwner,
        users: selectedUsers,
        projectAlert,
        taskAlert,
      },
    }));
  };
  const handleBack = (milestoneData) => {
    setProjectDetails((prev) => ({ ...prev, step: 'back', milestoneList: milestoneData }));
    setSelectedStartDate(projectDetails?.projectForm?.startDate);
    setSelectedEndDate(projectDetails?.projectForm?.endDate);
    setSelectedStatus(projectDetails?.projectForm?.status);
    setSelectedOwner(projectDetails?.projectForm?.owner);
    setSelectedUsers(projectDetails?.projectForm?.users);
    setProjectAlert(projectDetails?.projectForm?.projectAlert ?? false);
    setTaskAlert(projectDetails?.projectForm?.taskAlert ?? false);
  };

  const resetProjectDeatils = () => {
    const defaultDetails = {
      projectForm: {},
      milestoneList: {
        todoMilestone: {
          id: 1, name: 'To Do', colorId: milestoneColorConstants.TO_DO, editOnly: true, backgroundHexCode: '#ffd6d62a', darkBackgroundHexCode: '#ffd6d62a', textHexCode: '#E65F5F', darkTextHexCode: '#E65F5F',
        },
        completeMilestone: {
          id: 2, name: 'Completed', colorId: milestoneColorConstants.COMPLETED, editOnly: true, backgroundHexCode: '#9bf3c717', darkBackgroundHexCode: '#9bf3c717', textHexCode: '#4DC889', darkTextHexCode: '#4DC889',
        },
        inprogressMilestone: [
          {
            id: 3, name: 'In Progress', colorId: milestoneColorConstants.IN_PROGRESS, backgroundHexCode: '#ffe99926', darkBackgroundHexCode: '#ffe99926', textHexCode: '#DCAC02', darkTextHexCode: '#DCAC02', isNew: true,
          },
        ],
      },
      step: 'cancel',
    };
    setProjectDetails(defaultDetails);
    onHide();
    reset({
      projectName: '',
      startDate: null,
      endDate: null,
      status: null,
      owner: null,
      users: [],
    });
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectedOwner(null);
    setSelectedUsers([]);
    setSelectedStatus(null);
    setShowMilestoneModal(false);
    setProjectAlert(false);
    setTaskAlert(false);
  };

  const statusUpdateBasedOnStartDate = (startDate) => {
    const currentDate = moment();
    const startMomentDate = moment(startDate, 'DD/MM/YYYY');
    const isCurrentStartDateSame = (currentDate.isSame(startMomentDate, 'day')) && (currentDate.isSame(startMomentDate, 'month')) && (currentDate.isSame(startMomentDate, 'year'));
    if (currentDate.isBefore(startMomentDate)) {
      setSelectedStatus({ value: userStatusConstants.INACTIVE, label: 'Inactive' });
    } else if (currentDate.isAfter(startMomentDate) || isCurrentStartDateSame) {
      setSelectedStatus({ value: userStatusConstants.ACTIVE, label: 'Active' });
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header className="pb-2">
          <Modal.Title>
            <h2>Add Project</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-4">
          <Form>
            <Row>
              <Col md={6} className="mt-4">
                <Form.Group>
                  <Form.Label>Project name <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    {...register('projectName', {
                      required: { value: true, message: 'Required' },
                      validate: (value) => {
                        if (value.trim() === '') {
                          return 'Required';
                        }
                        if (value.trim().length < 3) {
                          return "Project name can't be less than 3 characters";
                        }
                        if (value.trim().length > 100) {
                          return "Project name can't be more then 100 characters";
                        }
                        return true;
                      },
                    })}
                  />
                  {errors.projectName && (
                  <div className="invalid-feedback d-block">
                    {errors.projectName.message}
                  </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group>
                  <Form.Label>Status <span className="text-danger">*</span></Form.Label>
                  <Select
                    value={selectedStatus}
                    options={statusOptions}
                    onChange={(value) => setSelectedStatus(value)}
                    styles={customStyles}
                    isDisabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mt-4">
                <Form.Group>
                  <Form.Label>Start Date <span className="text-danger">*</span></Form.Label>
                  <FlatPickr
                    value={selectedStartDate}
                    className="form-control"
                    options={{
                      dateFormat: 'd/m/Y',
                    }}
                    onChange={(dates, currentdateString) => {
                      setSelectedStartDate(currentdateString);
                      statusUpdateBasedOnStartDate(currentdateString);
                      clearErrors('startDate');
                    }}
                  />
                  {errors.startDate && (
                  <div className="invalid-feedback d-block">
                    {errors.startDate.message}
                  </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group>
                  <Form.Label>End Date <span className="text-danger">*</span></Form.Label>
                  <FlatPickr
                    value={selectedEndDate}
                    className="form-control"
                    onChange={(dates, currentdateString) => {
                      setSelectedEndDate(currentdateString);
                      clearErrors('endDate');
                    }}
                    options={{
                      dateFormat: 'd/m/Y',
                      minDate: selectedStartDate || undefined,
                    }}
                    disabled={!selectedStartDate}
                  />
                  {errors.endDate && (
                  <div className="invalid-feedback d-block">
                    {errors.endDate.message}
                  </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mt-4">
                <Form.Group>
                  <Form.Label>Owner <span className="text-danger">*</span></Form.Label>
                  <Select
                    value={selectedOwner}
                    onChange={(value) => {
                      setSelectedOwner(value);
                      clearErrors('owner');
                    }}
                    options={ownerOptions}
                    isDisabled={decryptedRole?.id === userRoleConstants.ADMIN}
                    styles={customStyles}
                  />
                  {errors.owner && (
                  <div className="invalid-feedback d-block">
                    {errors.owner.message}
                  </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mt-4">
                <Form.Group>
                  <Form.Label>Users <span className="text-danger">*</span></Form.Label>
                  <Select
                    value={selectedUsers}
                    onChange={(value) => {
                      setSelectedUsers(value);
                      clearErrors('users');
                    }}
                    options={userOptions}
                    styles={customStyles}
                    isMulti
                  />
                  {errors.users && (
                  <div className="invalid-feedback d-block">
                    {errors.users.message}
                  </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {loggedInUser?.client?.features?.[configurableModules.PUSH_NOTIFICATION] || loggedInUser?.client?.features?.[configurableModules.EMAIL_ALERTS] ? (
              <Row>
                <Col md={12} className="mt-4">
                  <Form.Group>
                    <Form.Label>Alerts</Form.Label>
                    <Row>
                      <Col md={6}>
                        <Form.Check
                          type="switch"
                          id="project-alert-switch"
                          label="Project Alerts"
                          checked={projectAlert}
                          onChange={() => setProjectAlert(!projectAlert)}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Check
                          type="switch"
                          id="task-alert-switch"
                          label="Task Alerts"
                          checked={taskAlert}
                          onChange={() => setTaskAlert(!taskAlert)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            ) : null}
            <div className="text-left pt-5 mt-5">
              <button
                type="button"
                className="btn btn-sm btn-light ms-3"
                onClick={resetProjectDeatils}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-sm btn-secondary ms-3"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {showMilestoneModal && (
      <Milestones mode="addMilestoneList" showAddProjectModal={handleShowModal} hideAddProjectModal={onHide} setShowMilestoneModal={setShowMilestoneModal} handleBack={handleBack} projectDetails={projectDetails} resetProjectDeatils={resetProjectDeatils} />
      )}
    </>
  );
}

export default AddProjectModal;
