/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import AddProjectModal from './AddProjectModal';
import Pagination from '../custom/Pagination';
import { useGetProjectsQuery } from '../../Redux/services/project';
import { useGetMasterDataQuery } from '../../Redux/services/masterData';
import { userRoleConstants, userStatusConstants } from '../../utils/constant';
import { decryptRole } from '../../utils/cryptoUtils';
import { useGetLoggedUserQuery } from '../../Redux/services/user';

function ViewProjectList() {
  const navigate = useNavigate();
  const dateRangePickerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [productLastUpdate, setProductLastUpdate] = useState('');
  const [selectedProjects, setSelectedProjects] = useState(null);
  const [selectedDurations, setSelectedDurations] = useState(null);
  const [selectedOwners, setSelectedOwners] = useState(null);
  const [decryptedRole, setDecryptedRole] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const size = 5;
  const [selectedProgress, setSelectedProgress] = useState(null);
  const { data: masterData, refetch: refetchMasterData } = useGetMasterDataQuery();
  const { data: loggedUser } = useGetLoggedUserQuery();
  const [filters, setFilters] = useState({
    page: 1,
    size: 5,
    sort: 'updatedAt',
    order: 'desc',
  });

  const projectName = masterData?.data.projects?.map((project) => ({ value: project.id, label: project.name })) || [];
  const owners = masterData?.data.owners?.map((owner) => ({
    value: owner.id,
    label: `${owner.firstName} ${owner.lastName}`,
  })) || [];
  const users = masterData?.data.users
    ?.filter((user) => (user.StatusId === userStatusConstants.ACTIVE))
    .map((user) => ({ value: user.id, label: user.fullName })) || [];
  const statusOptions = masterData?.data.projectStatuses?.map((status) => ({ value: status.id, label: status.name })) || [];
  const progressOptions = masterData?.data.projectProgresses?.map((progress) => ({ value: progress.id, label: progress.name })) || [];

  const { data: projectsData, isSuccess } = useGetProjectsQuery(filters);

  useEffect(() => {
    setSelectedOwners(decryptedRole?.id === userRoleConstants.ADMIN
      ? masterData?.data.owners?.filter((owner) => owner.id === loggedUser?.data?.userId).map((owner) => ({
        value: owner.id,
        label: `${owner.firstName} ${owner.lastName}`,
      }))
      : null);
  }, [masterData?.data, decryptedRole, loggedUser?.data?.userId]);

  useEffect(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    setDecryptedRole(roleDecrypted);
  }, []);

  useEffect(() => {
    fetchProjectsData(buildRequestBody());
  }, [currentPage, sortColumn, sortOrder]);

  useEffect(() => {
    if (projectsData?.data) {
      setTotalCount(projectsData.data.totalRecords);
    }
  }, [projectsData]);

  const fetchProjectsData = (requestBody) => {
    const queryParameters = {
      page: currentPage,
      size,
      sortColumn,
      sortOrder,
    };
    setFilters({ ...queryParameters, ...requestBody });
  };

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const handleSuccess = () => {
    setShowModal(false);
    refetchMasterData();
    fetchProjectsData();
  };

  const handleEventReceiveDate = (event, picker) => {
    const startDate = picker.startDate.format('DD/MM/YYYY');
    const endDate = picker.endDate.format('DD/MM/YYYY');
    picker.element.val(`${startDate} - ${endDate}`);
    setProductLastUpdate(`${startDate} - ${endDate}`);
  };

  const handleCancelReceiveDate = (event, picker) => {
    picker.element.val('');
    setProductLastUpdate('');
  };

  const handleRowClick = (projectId) => {
    navigate(`/projectDetails/${projectId}`);
  };

  const handleFilter = () => {
    setCurrentPage(1); // Reset to first page when filters are applied
    const requestBody = buildRequestBody();
    fetchProjectsData(requestBody);
  };

  const handleClearFilters = () => {
    if (decryptedRole?.id !== userRoleConstants.ADMIN) {
      setSelectedOwners(null);
    }
    setSelectedProjects(null);
    setSelectedDurations(null);
    setSelectedUsers([]);
    setSelectedStatus(null);
    setSelectedProgress(null);
    setProductLastUpdate('');
    document.getElementById('dateRangePickerReceiveDate').value = '';
    setCurrentPage(1); // Reset to first page
    fetchProjectsData(); // Fetch projects with default API parameters
  };

  const buildRequestBody = () => {
    const requestBody = {};

    if (selectedProjects && selectedProjects.value) {
      requestBody.name = selectedProjects.label;
    }
    if (selectedDurations && selectedDurations.value) {
      requestBody.duration = selectedDurations.value;
    }
    if (selectedOwners && selectedOwners.value) {
      requestBody.owner = selectedOwners.value;
    }
    if (selectedUsers && selectedUsers.length > 0) {
      requestBody.users = selectedUsers.map((user) => user.value);
    }
    if (selectedStatus && selectedStatus.value) {
      requestBody.status = selectedStatus.value;
    }
    if (selectedProgress && selectedProgress.value) {
      requestBody.progress = selectedProgress.value;
    }
    if (productLastUpdate) {
      const [startDate, endDate] = productLastUpdate.split(' - ');
      requestBody.startDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      requestBody.endDate = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return requestBody;
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
    setCurrentPage(1);
  };

  // Custom styles to hide the dropdown indicator
  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      fontWeight: '500',
      opacity: '.7',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '1.15rem',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: '0.7rem',
      paddingBottom: '0.7rem',
      borderRadius: '1.15rem',
      backgroundColor: 'var(--bs-dropdown-bg)',
      fontSize: '13px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: '500',
      color: 'var(--bs-gray-700)',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'var(--bs-dropdown-bg)',
      color: state.isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
      ':hover': {
        backgroundColor: 'var(--bs-component-hover-bg)',
      },
    }),
  };

  const customSeparatorStyles = {
    placeholder: (provided) => ({
      ...provided,
      fontWeight: '500',
      opacity: '.7',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '1.15rem',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: '0.7rem',
      paddingBottom: '0.7rem',
      borderRadius: '1.15rem',
      backgroundColor: 'var(--bs-dropdown-bg)',
      fontSize: '13px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'var(--bs-dropdown-bg)',
      color: state.isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
      ':hover': {
        backgroundColor: 'var(--bs-component-hover-bg)',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: '500',
      color: 'var(--bs-gray-700)',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: 'var(--bs-gray-300)',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      fontWeight: '500',
      color: 'var(--bs-gray-700)',
      fontSize: '1.1rem',
    }),
  };

  const handleClearLabelClick = () => {
    // Clear input field using ref2
    dateRangePickerRef.current.value = '';
  };

  return (
    <div className="row pt-0 mt-5">
      <div className="col-md-12">
        <div className="d-flex justify-content-between">
          <div>
            <h2 className="pb-0 mb-0 ms-0 pt-0">Projects</h2>
            <ul className="breadcrumb fw-semibold fs-base mb-1">
              <li className="breadcrumb-item text-muted">
                <Link to="/" className="text-muted text-hover-primary">Home</Link>
              </li>
              <li className="breadcrumb-item text-gray-900">Projects</li>
            </ul>
          </div>
          {decryptedRole?.id !== userRoleConstants.USER && (
            <div>
              <button type="button" className="btn btn-primary btn-sm" onClick={handleShowModal}>
                <i className="fa fa-plus fa-plus me-1" />Add
              </button>
              <AddProjectModal show={showModal} onHide={handleCloseModal} onSuccess={handleSuccess} handleShowModal={handleShowModal} />
            </div>
          )}
        </div>
        <div className="card priority-card mt-4">
          <div className="card-header d-block pt-3">
            <h5 className="card-title mb-3">Search Filter</h5>
            <div className="row pb-2 pb-2 g-3">
              <div className="col-md-2">
                <Select
                  isClearable
                  options={projectName}
                  placeholder="Project Name"
                  value={selectedProjects}
                  onChange={(selectedOptions) => setSelectedProjects(selectedOptions || [])}
                  styles={customStyles}
                />
              </div>
              <div className="col-md-2">
                <Select
                  isClearable
                  options={owners}
                  placeholder="Owner"
                  value={selectedOwners}
                  styles={customSeparatorStyles}
                  onChange={(selectedOptions) => setSelectedOwners(selectedOptions || [])}
                />
              </div>
              <div className="col-md-2">
                <Select
                  options={users}
                  placeholder="Users"
                  isMulti
                  value={selectedUsers}
                  styles={customSeparatorStyles}
                  onChange={(selectedOptions) => setSelectedUsers(selectedOptions || [])}
                />
              </div>
              <div className="col-md-1">
                <Select
                  isClearable
                  components={{
                    DropdownIndicator: () => null,
                  }}
                  styles={customSeparatorStyles}
                  options={statusOptions}
                  placeholder="Status"
                  value={selectedStatus}
                  onChange={(selectedOptions) => setSelectedStatus(selectedOptions || [])}
                />
              </div>
              <div className="col-md-2">
                <DateRangePicker
                  ref={dateRangePickerRef}
                  initialSettings={{
                    autoUpdateInput: false,
                    locale: { cancelLabel: 'Clear', format: 'YYYY/MM/DD' },
                    showDropdowns: true,
                    drops: 'down',
                  }}
                  onApply={handleEventReceiveDate}
                  onCancel={handleCancelReceiveDate}
                  onShow={() => {
                    // Add event listener to the clear button when the DateRangePicker is shown
                    const clearButton = document.querySelector('.daterangepicker .ranges li[data-range-key="custom"]');
                    if (clearButton) {
                      clearButton.addEventListener('click', handleClearLabelClick);
                    }
                  }}
                  onHide={() => {
                    // Remove event listener when the DateRangePicker is hidden
                    const clearButton = document.querySelector('.daterangepicker .ranges li[data-range-key="custom"]');
                    if (clearButton) {
                      clearButton.removeEventListener('click', handleClearLabelClick);
                    }
                  }}
                >
                  <input
                    type="text"
                    id="dateRangePickerReceiveDate"
                    className="form-control"
                    placeholder="Select Date Range"
                    defaultValue={productLastUpdate}
                    autoComplete="off"
                  />
                </DateRangePicker>
              </div>
              <div className="col-md-1">
                <Select
                  options={progressOptions}
                  placeholder="Progress"
                  value={selectedProgress}
                  isClearable
                  components={{
                    DropdownIndicator: () => null,
                  }}
                  styles={customSeparatorStyles}
                  onChange={(selectedOption) => setSelectedProgress(selectedOption)}
                />
              </div>
              <div className="col-md-2">
                <button type="button" className="btn btn-sm btn-secondary me-sm-3 me-1" onClick={handleFilter}>Filter</button>
                <button
                  type="button"
                  className="btn btn-label-secondary"
                  onClick={handleClearFilters}
                >Clear
                </button>
              </div>
            </div>
          </div>
          <div className="card-body py-4">
            <div className="table-responsive">
              <table className="table to-do-table align-middle table-row-dashed fs-6 gy-5 gs-7 no-footer">
                <thead>
                  <tr className="text-start fw-bold fs-7 text-uppercase gs-0">
                    <th style={{ width: '120px' }} onClick={() => handleSort('name')}>
                      <span role="button">Name</span>{sortColumn === 'name' && <i className={`fa fa-chevron-${sortOrder === 'asc' ? 'up' : 'down'}`} style={{ fontSize: '10px', width: '10px', height: '10px' }} />}
                    </th>
                    <th style={{ width: '80px' }} onClick={() => handleSort('owner')}>
                      <span role="button">Owner</span>{sortColumn === 'owner' && <i className={`fa fa-chevron-${sortOrder === 'asc' ? 'up' : 'down'}`} style={{ fontSize: '10px', width: '10px', height: '10px' }} />}
                    </th>
                    <th style={{ width: '60px' }}>Users</th>
                    <th style={{ width: '100px' }} onClick={() => handleSort('status')}>
                      <span role="button">Status</span>{sortColumn === 'status' && <i className={`fa fa-chevron-${sortOrder === 'asc' ? 'up' : 'down'}`} style={{ fontSize: '10px', width: '10px', height: '10px' }} />}
                    </th>
                    <th style={{ width: '120px' }} onClick={() => handleSort('startDate')}>
                      <span role="button">Start Date </span>{sortColumn === 'startDate' && <i className={`fa fa-chevron-${sortOrder === 'asc' ? 'up' : 'down'}`} style={{ fontSize: '10px', width: '10px', height: '10px' }} />}
                    </th>
                    <th style={{ width: '120px' }} onClick={() => handleSort('endDate')}>
                      <span role="button">End Date </span>{sortColumn === 'endDate' && <i className={`fa fa-chevron-${sortOrder === 'asc' ? 'up' : 'down'}`} style={{ fontSize: '10px', width: '10px', height: '10px' }} />}
                    </th>
                    <th style={{ width: '100px' }}>Progress</th>
                  </tr>
                </thead>

                <tbody className="text-gray-600 fw-semibold">
                  {isSuccess && Array.isArray(projectsData.data.projects) && projectsData.data.projects.length !== 0 && projectsData.data.projects.map((project) => (
                    <tr key={project.id} onClick={() => handleRowClick(project.id)} style={{ cursor: 'pointer' }}>
                      <td style={{ fontWeight: 'bold', color: '#000' }}>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 300 }}
                          overlay={<Tooltip id="button-tooltip">{project?.name}</Tooltip>}
                        >
                          <div style={{ width: '200px' }} className="text-truncate">
                            {project?.name}
                          </div>
                        </OverlayTrigger>
                      </td>
                      <td>{project.owner && (
                        <div className="symbol symbol-circle symbol-25px" title={project?.owner?.fullName ?? project?.owner?.initials}>
                          <div className="symbol-label solid bg-light-primary">
                            <span className="fs-7 fw-normal">{project.owner.initials}</span>
                          </div>
                        </div>
                      )}
                      </td>
                      <td>
                        <div className="symbol-group symbol-hover mb-1">
                          {project.ProjectUsers.length > 3 ? (
                            <>
                              {project.ProjectUsers.slice(0, 2).map((projectUser, index) => {
                                const backgroundColors = ['bg-warning', 'bg-primary'];
                                const textColors = ['text-inverse-warning', 'text-inverse-primary'];
                                const bgColor = backgroundColors[index % 2];
                                const textColor = textColors[index % 2];
                                return (
                                  <div
                                    key={projectUser.id}
                                    className="symbol symbol-25px symbol-circle"
                                    data-bs-toggle="tooltip"
                                    title={projectUser.fullName}
                                  >
                                    <span className={`symbol-label ${index % 2 === 0 ? 'solid' : 'outline'} fs-7 fw-normal ${bgColor} ${textColor}`}>
                                      {projectUser.initials}
                                    </span>
                                  </div>
                                );
                              })}
                              <span
                                className="symbol symbol-25px symbol-circle"
                              >
                                <span className="symbol-label solid fs-7 fw-normal">+{project.ProjectUsers.length - 2}</span>
                              </span>
                            </>
                          ) : (
                            <>
                              {project.ProjectUsers.map((projectUser, index) => {
                                const backgroundColors = ['bg-warning', 'bg-danger'];
                                const textColors = ['text-inverse-warning', 'text-inverse-danger'];
                                const bgColor = backgroundColors[index % 2];
                                const textColor = textColors[index % 2];
                                return (
                                  <div
                                    key={projectUser.id}
                                    className="symbol symbol-25px symbol-circle"
                                    data-bs-toggle="tooltip"
                                    title={projectUser.fullName}
                                  >
                                    <span className={`symbol-label ${index % 2 === 0 ? 'solid' : 'outline'} fs-7 fw-normal ${bgColor} ${textColor}`}>
                                      {projectUser.initials}
                                    </span>
                                  </div>
                                );
                              })}
                            </>
                          )}
                          {project?.ProjectUsers.length === 0 && (
                            <div className="symbol symbol-circle symbol-25px">
                              <div className="symbol-label solid bg-light-secondary">
                                <span className="fs-7">N/A</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className={`badge ${project?.ProjectStatus?.name === 'Completed' ? 'badge-light-info' : project?.ProjectStatus?.name === 'Active' ? 'badge-light-warning' : 'badge-light-dark'} fw-bold`}>{project?.ProjectStatus?.name || '--'}
                        </div>
                      </td>
                      <td>{project.startDate ? moment(project.startDate, 'YYYY-MM-DD').format('DD MMM YY') : '--'}</td>
                      <td>{project.endDate ? moment(project.endDate, 'YYYY-MM-DD').format('DD MMM YY') : '--'}</td>
                      <td>
                        <div className={`badge ${project?.progress?.name === 'Delayed' && 'badge-light-danger'} ${project?.progress?.name === 'On Time' && 'badge-light-success'} fw-bold`}>
                          {project?.progress?.name || '--'}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {isSuccess && Array.isArray(projectsData.data.projects) && projectsData.data.projects.length > 0 ? (
                <Pagination
                  dataPerPage={size}
                  totalData={totalCount}
                  currentPage={currentPage}
                  paginate={setCurrentPage}
                />
              )
                : <div className="text-center fw-bold mx-auto m-4">No Records Found!</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewProjectList;
