import { useState } from 'react';

function CustomizableFields({
  customFields, handleCustomFieldChange, setCustomCheckedFields, setCurrentPage,
  templateConfigurationData, isMobileCompatible, handleMobileCompatibility,
}) {
  const [searchFields, setSearchFields] = useState('');

  const renderTableCells = (type) => {
    const tableRows = [];
    const duplicateTableRows = [];

    if (customFields) {
      Object.keys(customFields)?.forEach((key) => {
        const item = customFields[key];
        const matchesSearch = item.label.toLowerCase().includes(searchFields.toLowerCase());

        if (matchesSearch) {
          const row = (
            <tr key={`${item.label}_${type}`}>
              <td>{item.label}</td>
              <td aria-label="table">
                <input
                  className="form-check-input"
                  type="checkbox"
                  data-column="0"
                  value="report"
                  checked={item.reportChecked}
                  disabled={item.label === 'Task No' || (isMobileCompatible && item.label === 'Milestones wise Task Count')}
                  style={{ cursor: (item.label === 'Task No' || (isMobileCompatible && item.label === 'Milestones wise Task Count')) ? 'not-allowed' : 'pointer', pointerEvents: 'auto' }}
                  onChange={(e) => handleCustomFieldChange(e.target.value, key)}
                />
              </td>
              {item?.allowFilter && (
                <td aria-label="table">
                  <input
                    className="form-check-input"
                    style={{ cursor: 'pointer', pointerEvents: 'auto' }}
                    type="checkbox"
                    data-column="0"
                    value="filter"
                    disabled={isMobileCompatible}
                    checked={item.filterChecked}
                    onChange={(e) => handleCustomFieldChange(e.target.value, key)}
                  />
                </td>
              )}
            </tr>
          );
          if (item.duplicate) {
            duplicateTableRows.push(row);
          } else {
            tableRows.push(row);
          }
        }
        return null;
      });

    }

    if (type === 'duplicate') {
      return duplicateTableRows;
    }

    return tableRows;
  };

  const handleSearchFields = (e) => {
    setSearchFields(e.target.value);
  };

  const handleSave = () => {
    if (customFields) {
      const reqBody = (Object.keys(customFields)
        ?.filter((key) => (customFields[key].reportChecked) || (customFields[key].filterChecked))
        .map((key) => {
          const template = templateConfigurationData?.find((field) => field.fieldName === key);
          return {
            label: customFields[key]?.label,
            key,
            report: customFields[key]?.reportChecked ?? false,
            filter: customFields[key]?.filterChecked ?? false,
            id: template?.id,
          };
        }));

      setCustomCheckedFields(reqBody);
      setCurrentPage(1);
    }
  };

  return (
    <div className="modal fade filter-dropdown position-center mt-6" tabIndex="-1" id="kt_modal_1">
      <div className="modal-dialog modal-dialog-centered mw-600px">
        <div className="modal-content">
          <div className="modal-header py-3">
            <div>
              <span className="card-label fw-bold text-gray-800">Customize fields</span>
              <p className="text-gray-400 mt-1 mb-0 fw-semibold fs-6">You can select below the columns and filters for your report.</p>
            </div>
            <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
              X
            </div>
          </div>
          <div className="separator border-gray-200" />
          <div className="modal-body">
            <div className="d-flex justify-content-between p-5">
              <div className="form-check form-switch form-check-custom form-check-solid me-10 pt-5 pb-2">
                <label htmlFor="mobile-compatible" className="form-label me-3 mt-1 " aria-label="Mobile Compatible">Mobile Compatible</label>
                <input
                  id="mobile-compatible"
                  className="form-check-input h-20px w-30px cursor-pointer"
                  type="checkbox"
                  onChange={(e) => handleMobileCompatibility(e)}
                  checked={isMobileCompatible}
                />
              </div>
              <div className="d-flex flex-row w-300px flex-center pt-5 pb-2">
                <label htmlFor="search" className="form-label me-3 mt-1 " aria-label="Search">Search:</label>
                <input
                  id="search"
                  className="form-control form-control-solid"
                  onChange={handleSearchFields}
                  value={searchFields}
                />
              </div>
            </div>
            <div className="h-400px scroll-y p-5">
              <table className="table to-do-table align-middle table-row-dashed fs-6 gy-5 gs-7" id="example">
                <thead>
                  <tr className="text-start fw-bold fs-7 text-uppercase gs-0">
                    <th className="min-w-80px">FIELD</th>
                    <th className="min-w-80px">ADD TO TIMESHEET</th>
                    <th className="min-w-80px">ADD TO FILTER</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-semibold ">
                  {renderTableCells('normal')}
                </tbody>
              </table>
            </div>
            <div className="text-center pt-5 pb-4">
              <button
                type="button"
                className="btn btn-light me-3"
                data-bs-dismiss="modal"
              >Discard
              </button>
              <button
                type="button"
                onClick={handleSave}
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomizableFields;
