import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useGetMasterDataQuery } from '../../Redux/services/masterData';
import StrictModeDroppable from './helpers/strictModeDroppable';
import { useGetTaskWithFiltersQuery, useEditTaskMutation } from '../../Redux/services/kanban';
import { userRoleConstants } from '../../utils/constant';

export default function KanbanBoard() {
  const { data: masterData } = useGetMasterDataQuery();
  const loggedInUser = useSelector((state) => state.user);

  const projectDetail = Array.isArray(masterData?.data?.projects)
    ? masterData.data.projects.map((project) => ({
      value: project.id,
      label: project.name,
    }))
    : [];
  const initialProject = projectDetail[0] || null;

  const getInitialProject = () => {
    const savedProjectName = localStorage.getItem('projectName');
    if (savedProjectName) {
      const foundProject = projectDetail.find(
        (project) => project.value === savedProjectName,
      );
      if (foundProject) {
        return foundProject;
      }
    }
    const savedProject = localStorage.getItem('lastSelectedProject');
    if (savedProject) {
      return JSON.parse(savedProject);
    }
    return initialProject;
  };

  const [lastSelectedProject, setLastSelectedProject] = useState(getInitialProject);
  const userOptions = Array.isArray(masterData?.data?.projects)
    ? masterData.data.projects
      .filter((project) => project.id === lastSelectedProject.value)[0]?.ProjectUser
      .map((user) => ({
        value: user.id,
        label: user.fullName,
      }))
    : [];
  const [selectedUsers, setSelectedUsers] = useState(
    loggedInUser?.role?.id !== userRoleConstants.SUPER_ADMIN
    && loggedInUser?.userId ? [loggedInUser?.userId] : [],
  );

  const [error, setError] = useState(null);

  const { data: tasksData } = useGetTaskWithFiltersQuery(
    { projectId: lastSelectedProject?.value, users: selectedUsers },
  );

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (tasksData && tasksData?.data?.milestoneWiseTasks) {
      setColumns(tasksData.data.milestoneWiseTasks);
    }
  }, [tasksData]);

  const [searchQuery, setSearchQuery] = useState('');

  const [editTaskMutation] = useEditTaskMutation();

  useEffect(() => {
    if (!lastSelectedProject && initialProject) {
      setLastSelectedProject(initialProject);
    }
    if (lastSelectedProject) {
      localStorage.setItem('lastSelectedProject', JSON.stringify(lastSelectedProject));
    }
  }, [initialProject, lastSelectedProject]);

  const handleProjectChange = (selectedOption) => {
    setLastSelectedProject(selectedOption);
  };

  const handleUserChange = (selectedOption) => {
    setSelectedUsers(selectedOption.map((user) => user.value));
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      return;
    }

    const sourceColumn = columns.find((column) => column.id === Number(source.droppableId));
    const destColumn = columns.find((column) => column.id === Number(destination.droppableId));

    if (!sourceColumn || !destColumn) {
      setError(error?.data?.message || 'Source column or destination column not found.');
      return;
    }

    const newSourceItems = [...sourceColumn.tasks];
    const newDestItems = [...destColumn.tasks];

    const [removed] = newSourceItems.splice(source.index, 1);
    newDestItems.splice(destination.index, 0, removed);

    const newColumns = columns.map((column) => {
      if (column.id === Number(source.droppableId)) {
        return { ...column, tasks: newSourceItems };
      }
      if (column.id === Number(destination.droppableId)) {
        return { ...column, tasks: newDestItems };
      }
      return column;
    });

    setColumns(newColumns);

    try {
      await editTaskMutation({
        id: removed.id,
        data: { milestoneId: Number(destination.droppableId) },
      });
      window.toastr.success('The status of task has been updated!');
      setError(null);
    } catch (err) {
      setError(err?.data?.message || 'An error occurred while updating the task status.');
      window.toastr.error('Something went wrong!');
    }
  };

  const filterItemsBySearch = (items) => {
    if (!searchQuery) return items;
    const lowercasedQuery = searchQuery.toLowerCase();
    return items.filter((item) => {
      const lowercasedTitle = item.title.toLowerCase();
      return lowercasedTitle.includes(lowercasedQuery);
    });
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      borderRadius: '1.15rem',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: '0.7rem',
      paddingBottom: '0.7rem',
      borderRadius: '1.15rem',
      backgroundColor: 'var(--bs-dropdown-bg)',
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: 'var(--bs-dropdown-bg)',
      ':hover': {
        backgroundColor: 'var(--bs-component-hover-bg)',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };

  const theme = document.querySelector('[data-bs-theme]')?.getAttribute('data-bs-theme');

  return (
    <>
      <div className="justify-content-between progress-button mt-5">
        <h2 className="pb-0 mb-0 ms-0 pt-0">Kanban Board</h2>
        <ul className="breadcrumb fw-semibold fs-base mb-1">
          <li className="breadcrumb-item text-muted">
            <Link to="/" className="text-muted text-hover-primary">Home</Link>
          </li>
          <li className="breadcrumb-item text-gray-900">Kanban Board</li>
        </ul>
      </div>
      <div className="row mb-4">
        <div className="col-lg-2 mt-5">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by task name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              aria-label="Search"
            />
          </div>
        </div>
        <div className="col-lg-6 ms-auto">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <Select
                placeholder="Select Users"
                className="mt-7"
                options={userOptions}
                onChange={handleUserChange}
                aria-label="Select users"
                styles={customStyles}
                isClearable
                isMulti
                maxMenuHeight={300}
                defaultValue={
                  loggedInUser?.role?.id !== userRoleConstants.SUPER_ADMIN
                    ? { label: loggedInUser.fullName, value: loggedInUser.userId }
                    : null
                }
              />
            </div>
            <div className="col-lg-3">
              <Select
                placeholder="Select Project"
                className="mt-7"
                onChange={handleProjectChange}
                options={projectDetail}
                value={lastSelectedProject}
                aria-label="Select project"
                styles={customStyles}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="kt_docs_jkanban_rich">
        {columns.length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="d-flex flex-row gap-6 overflow-auto">
              {columns?.map((column) => (
                <div className="col-lg-4 mb-5" style={{ width: '280px' }} key={column.id}>
                  <div className="card card-custom card-stretch gutter-b" style={{ height: '66vh', backgroundColor: theme === 'light' ? column?.milestoneColor?.backgroundHexCode : column?.milestoneColor?.darkBackgroundHexCode, color: theme === 'light' ? column?.milestoneColor?.textHexCode : column?.milestoneColor?.darkTextHexCode }}>
                    <div
                      style={{
                        fontSize: '1.2rem',
                        padding: '15px',
                        paddingBottom: 0,
                        height: 'fit-content',
                      }}
                    >
                      <div style={{ fontWeight: '500' }}>
                        {column?.name}
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 300 }}
                          overlay={(
                            <Tooltip>
                              {selectedUsers.length === 1 && selectedUsers[0] === loggedInUser.userId ? 'Assigned to me' : 'Assigned to me / Total tasks'}
                            </Tooltip>
                        )}
                        >
                          <span className="ms-2">({column.assignedToMeTasks}{selectedUsers.length === 1 && selectedUsers[0] === loggedInUser.userId ? '' : `/${column.taskCount}`})</span>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div>
                      <StrictModeDroppable
                        droppableId={column?.id?.toString()}
                        key={column?.id}
                        direction="vertical"
                      >
                        {(droppableProvided) => (
                          <div
                            ref={droppableProvided.innerRef}
                            role="list"
                            style={{ padding: '20px' }}
                          >
                            {filterItemsBySearch(column?.tasks)?.map((task, index) => (
                              <Draggable
                                key={task.uniqueTaskId}
                                draggableId={task.uniqueTaskId}
                                index={index}
                              >
                                {(draggableProvided, draggableSnapshot) => (
                                  <div
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                    className="task-card"
                                    style={{
                                      userSelect: 'none',
                                      padding: '15px',
                                      margin: '0 0 20px 0',
                                      backgroundColor: draggableSnapshot.isDragging ? '#f4f5f7' : '#fff',
                                      color: '#333',
                                      borderRadius: '1.15rem',
                                      boxShadow: '0px 0px 13px 0px rgba(0, 0, 0, 0.05)',
                                      ...draggableProvided.draggableProps.style,
                                    }}
                                  >
                                    <Link to={`/tasks/${task.uniqueTaskId}`} title="Go to Task">
                                      <div className="task-id">#{task.uniqueTaskId}</div>
                                    </Link>
                                    <div className="d-flex flex-column align-items-start border-bottom pb-3" style={{ overflowWrap: 'anywhere' }}>
                                      <span className="text-gray-900-50 pt-2 mb-1" title={task.title}>{task.title}</span>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-start pt-3" style={{ position: 'relative', top: '3px' }}>

                                      <div className="symbol-group symbol-hover mb-1 me-2">
                                        {task?.taskUsers?.length > 3 ? (
                                          <>
                                            {task?.taskUsers?.slice(0, 2).map((user, ind) => {
                                              const backgroundColors = ['bg-warning', 'bg-primary'];
                                              const textColors = ['text-inverse-warning', 'text-inverse-primary'];
                                              const bgColor = backgroundColors[ind % 2];
                                              const textColor = textColors[ind % 2];
                                              return (
                                                <div
                                                  key={user.id}
                                                  className="symbol symbol-25px symbol-circle"
                                                  data-bs-toggle="tooltip"
                                                  title={user.userName}
                                                >
                                                  <span className={`symbol-label ${ind % 2 === 0 ? 'solid' : 'outline'} fs-7 fw-normal ${bgColor} ${textColor}`}>
                                                    {user.initials}
                                                  </span>
                                                </div>
                                              );
                                            })}
                                            <span
                                              className="symbol symbol-25px symbol-circle"
                                              data-bs-toggle="tooltip"
                                              title={`+${task.taskUsers.length - 2} more`}
                                            >
                                              <span className="symbol-label solid fs-7 fw-normal">+{task.taskUsers.length - 2}</span>
                                            </span>
                                          </>
                                        ) : task?.taskUsers?.map((user, i) => {
                                          const backgroundColors = ['bg-warning', 'bg-danger'];
                                          const textColors = ['text-inverse-warning', 'text-inverse-danger'];
                                          const bgColor = backgroundColors[i % 2];
                                          const textColor = textColors[i % 2];
                                          return (
                                            <div
                                              key={user.id}
                                              className="symbol symbol-25px symbol-circle"
                                              data-bs-toggle="tooltip"
                                              title={user.userName}
                                            >
                                              <span className={`symbol-label ${i % 2 === 0 ? 'solid' : 'outline'} fs-7 fw-normal ${bgColor} ${textColor}`}>
                                                {user.initials}
                                              </span>
                                            </div>
                                          );
                                        })}

                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {droppableProvided.placeholder}
                          </div>
                        )}
                      </StrictModeDroppable>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </DragDropContext>
        ) : ((
          <div className="row mt-5 pt-0 px-5">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="row mt-5 pt-0">
                <div className="col-xl-12 col-lg-12 col-md-12">

                  <div className="row gy-5 g-xl-10">

                    <div className="col-xl-12">
                      <div className="card card-flush mb-6 mb-xl-9 chart_card h-100">
                        <div className="card-body pt-9 pb-5">
                          <div className="row justify-content-center text-center">
                            <div className="col-lg-10">
                              <img src="../assets/media/warning.png" alt="" className="pt-1" />
                              <p className="lead mt-4 text-600 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">No milestones present in this project</p>

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
