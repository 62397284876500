import { useState, useEffect, useCallback } from 'react';
import MilestoneList from './MilestoneList';
import { useGetProjectMilestonesQuery } from '../../../Redux/services/project';
import { milestoneColorConstants } from '../../../utils/constant';

function Milestones({
  projectId, mode, showAddProjectModal, hideAddProjectModal, setShowMilestoneModal, handleBack,
  projectDetails, milestoneWiseTaskCount, resetProjectDeatils,
}) {
  const [milestoneList, setMilestoneList] = useState({
    todoMilestone: {
      id: 1, name: 'To Do', colorId: milestoneColorConstants.TO_DO, editOnly: true, backgroundHexCode: '#ffd6d62a', darkBackgroundHexCode: '#3C353F', textHexCode: '#E65F5F', darkTextHexCode: '#E65F5F',
    },
    completeMilestone: {
      id: 2, name: 'Completed', colorId: milestoneColorConstants.COMPLETED, editOnly: true, backgroundHexCode: '#9bf3c717', darkBackgroundHexCode: '#9bf3c717', textHexCode: '#4DC889', darkTextHexCode: '#4DC889',
    },
    inprogressMilestone: [
      {
        id: 3, name: 'In Progress', colorId: milestoneColorConstants.IN_PROGRESS, backgroundHexCode: '#ffe99926', darkBackgroundHexCode: '#ffe99926', textHexCode: '#DCAC02', darkTextHexCode: '#DCAC02', isNew: true,
      },
    ],
  });
  const [modalState, setModalState] = useState({ activeModal: null, modalValues: null });

  const {
    data: projectMilestones, isLoading, isError, error,
  } = useGetProjectMilestonesQuery(projectId, { skip: !projectId });
  if (isError) {
    window.toastr.error(error.message ?? 'Something went wrong!');
  }

  const openMilestoneList = useCallback(() => {
    setModalState({ activeModal: 'milestoneList', modalValues: null });
    if (mode === 'addMilestoneList') {
      hideAddProjectModal();
    }
  }, [hideAddProjectModal, mode]);
  const openMilestoneDetailsForm = (modalValues) => setModalState({ activeModal: 'milestoneDetailsForm', modalValues });
  const closeModal = () => {
    switch (mode) {
      case 'addMilestoneList':
        setShowMilestoneModal(false);
        handleBack(milestoneList);
        showAddProjectModal();
        break;
      case 'editMilestoneList':
        setModalState({ activeModal: null, modalValues: null });
        setTimeout(() => {
          setMilestoneList((prev) => ({
            ...prev,
            ...projectMilestones.data,
            todoMilestone: { ...projectMilestones.data.todoMilestone, editOnly: true },
            completeMilestone: { ...projectMilestones.data.completeMilestone, editOnly: true },
          }));
        }, 0);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (projectMilestones?.data) {
      setMilestoneList((prev) => ({
        ...prev,
        ...projectMilestones.data,
        todoMilestone: { ...projectMilestones.data.todoMilestone, editOnly: true },
        completeMilestone: { ...projectMilestones.data.completeMilestone, editOnly: true },
      }));
    }
  }, [projectMilestones]);

  useEffect(() => {
    if (mode === 'addMilestoneList') {
      openMilestoneList();
      setMilestoneList(projectDetails?.milestoneList);
    }
  }, [mode, openMilestoneList, projectDetails?.milestoneList]);

  return (
    <>
      {mode === 'editMilestoneList' ? (
        <button className="btn btn-sm btn-primary" type="button" onClick={openMilestoneList}>
          <i className="bi bi-flag-fill me-1" />Milestones
        </button>
      ) : null}
      <MilestoneList
        mode={mode}
        milestoneWiseTaskCount={milestoneWiseTaskCount}
        projectDetails={projectDetails}
        resetProjectDeatils={resetProjectDeatils}
        isLoading={isLoading}
        projectId={projectId}
        show={modalState.activeModal === 'milestoneList'}
        onHide={closeModal}
        modalState={modalState}
        openMilestoneList={openMilestoneList}
        openMilestoneDetailsForm={openMilestoneDetailsForm}
        milestoneList={milestoneList}
        setMilestoneList={setMilestoneList}
      />
    </>
  );
}

export default Milestones;
